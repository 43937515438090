import React, {
    useState,
    useContext, useEffect,
} from 'react'
import { navigate } from "gatsby"
import {
    Page,
    Layout,
    Card,
    DisplayText,
    Icon,
    Stack,
    ButtonGroup,
    Button,
    TextContainer,
    TextStyle,
    FormLayout,
} from "@shopify/polaris"
import {
    ExternalSmallMinor,
    QuestionMarkMajor
} from '@shopify/polaris-icons';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import FirebaseContext from '../../../providers/firebase';
import styled from 'styled-components'
import CreateOfferSteps from './createOfferSteps'
import getMainThemeId from "../../../helpers/getMainThemeId";
import CartUpsellAddSection from '../../../images/CartUpsellAddSection.gif';

const CartOfferPreviewWrapper = styled.div`
    padding: 50px 0 10px 0;
    .preview-button {
        padding: 25px 0 15px 0;
        .Polaris-Button {
            width: 485px;
        }
    }
    .instructions {
        display: flex;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        max-width: 575px;
        margin: auto;
        .instructions-image {
            margin-bottom: 20px;
            padding: 10px;
            border: 1px solid #AAAAAA;
            border-radius: 8px;
            background: #F8F8F8;
            div {
                border: 1px solid #AAAAAA;
                border-radius: 8px;
                img {
                    max-width: 550px;
                }
            }
        }
    }
    .action-buttons {
        padding: 15px 0 25px 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
`
const ButtonsWrapper = styled.div`
    margin-top: 15px;
    .Polaris-ButtonGroup__Item:first-child {
        button {
            color: #008060;
            svg {
                fill: #008060;
            }
        }
    }
`
function CartOfferPreview(props) {
    const { shop, token, host } = useContext(FirebaseContext);
    const [active, setActive] = useState(false);
    const [mainThemeId, setMainThemeId] = useState(null);

    const getThemeId = async () => {
        try {
            const fetchedThemeId = await getMainThemeId(token, shop, host);
            console.log('fetchedThemeId in CartOfferPreview: ', fetchedThemeId)
            if (fetchedThemeId?.data?.mainThemeId) {
                setMainThemeId(fetchedThemeId.data.mainThemeId);
            }
        } catch (err) {
            console.log('Error in set main theme id: ', err);
        }
    }

    useEffect(() => {
        getThemeId().catch((err) => console.log('Error in fetch main theme id: ', err));
    }, []);

    return (
      <Page
        breadcrumbs={[{content: 'Offers', url: '/app/offers'}]}
        title={
            `Create Offer`
        }
      >
          <Layout>
              <Layout.Section>
                  <div style={{margin: '20px 0 10px -25px'}}>
                      <CreateOfferSteps step={3}/>
                  </div>
                  <Card>
                      <Card.Section>
                          <CartOfferPreviewWrapper>
                              <div className='instructions'>
                                  <div className="instructions-image">
                                      <div>
                                          <img src={CartUpsellAddSection} alt={'CartUpsellAddSection'}/>
                                      </div>
                                  </div>
                                  <FormLayout>
                                      <TextContainer spacing="loose">
                                          <DisplayText size="small">
                                              Last step! Add your offer to the
                                              <TextStyle variation='strong'> app section </TextStyle>
                                              of your cart page
                                          </DisplayText>
                                          <div>
                                              <TextStyle variation="subdued">Add the UpsellPlus theme extension to the cart app section with the theme editor</TextStyle>
                                          </div>
                                      </TextContainer>
                                  </FormLayout>
                              </div>
                              <div className='action-buttons'>
                                  <div style={{textAlign:'center'}}>
                                      <Button primary={true} onClick={
                                          () => {
                                              setActive(true)
                                              window.open(`https://${shop}/admin/themes/${mainThemeId}/editor?previewPath=%2Fcart`, '_blank')
                                          }
                                      }>
                                          <Stack distribution="center" alignment='center' spacing='tight'>
                                              <span>Go to theme editor</span>
                                              <Icon
                                                source={ExternalSmallMinor}
                                                color="base"
                                              />
                                          </Stack>
                                      </Button>
                                  </div>
                                  <ButtonsWrapper>
                                      <Stack distribution="center">
                                          <ButtonGroup>
                                              <Button plain onClick={
                                                  () => {
                                                      if(window && window.$crisp){
                                                          window.$crisp.push(["do", "chat:open"]);
                                                      }
                                                  }
                                              }>
                                                  <Stack distribution="center" alignment='center' spacing='tight'>
                                                      <Icon
                                                        source={QuestionMarkMajor}
                                                        color="base"
                                                      />
                                                      <span>Get help</span>
                                                  </Stack>
                                              </Button>
                                              {active ?
                                                <Button primary={true} onClick={
                                                    () => {
                                                        navigate('/app/offers', { replace: true,})
                                                    }
                                                }>Mark as done</Button>
                                                :
                                                <Button primary disabled>
                                                    Mark as done
                                                </Button>
                                              }
                                          </ButtonGroup>
                                      </Stack>
                                  </ButtonsWrapper>
                              </div>
                          </CartOfferPreviewWrapper>
                      </Card.Section>
                  </Card>
              </Layout.Section>
          </Layout>
      </Page>
    )
}

export default CartOfferPreview;